var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"header":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"mb-0"},[_vm._v("Өр зээлгүй тодорхойлолт")])]},proxy:true}])},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false,"color":this.$config.LOADER.color,"opacity":this.$config.LOADER.opacity,"background-color":this.$config.LOADER.backgroundColor,"z-index":10000},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-form-group',{attrs:{"label":"Дугаар"}},[_c('b-form-input',{attrs:{"placeholder":"Дугаар"},model:{value:(_vm.form.number),callback:function ($$v) {_vm.$set(_vm.form, "number", $$v)},expression:"form.number"}})],1),_c('b-form-group',{attrs:{"label":"Хаана"}},[_c('b-form-input',{attrs:{"placeholder":"Бичнэ үү"},model:{value:(_vm.form.where),callback:function ($$v) {_vm.$set(_vm.form, "where", $$v)},expression:"form.where"}})],1),(_vm.checkPermission(
            'admin.loan-repayment-overdue.create-statement-no-loan'
        )
        )?_c('b-button',{staticClass:"pull-left mr-3",attrs:{"variant":"success","disabled":_vm.emailButtonLoading},on:{"click":function($event){return _vm.sendNoLoanHTML()}}},[(!_vm.emailButtonLoading)?_c('span',[_c('i',{staticClass:"fa fa-envelope-o"}),_vm._v(" Имэйл илгээх")]):_c('span',[_vm._v("Уншиж байна")])]):_vm._e(),(_vm.checkPermission(
            'admin.loan-repayment-overdue.create-statement-no-loan'
        )
        )?_c('b-button',{staticClass:"pull-left mr-3",attrs:{"variant":"info"},on:{"click":function($event){return _vm.getNoLoanHTML()}}},[_c('i',{staticClass:"fa fa-file-o"}),_vm._v(" харах")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }