<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
      :width="30"
      :height="30"
    ></loading>

    <b-alert variant="warning" v-if="data == null" show class="mb-0"
      >Мэдээлэл байхгүй байна</b-alert
    >

    <div v-else>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Төлөв</label>
                <select class="form-control" v-model="status">
                  <option
                    :value="value"
                    v-for="(name, value, index) in status_list"
                    :key="index"
                    >{{ name }}</option
                  >
                </select>
              </div>
              <div class="form-group" v-if="status == 'UN_CONFIRM'">
                <label for="">Бусад</label>
                {{ un_confirm_other }}
                <select class="form-control" v-model="un_confirm_other">
                  <option
                    :value="item.id"
                    v-for="(item, index) in un_confirm_other_list"
                    :key="index"
                    >{{ item.name }}</option
                  >
                </select>
              </div>
              <div class="form-group">
                <label for="">Тайлбар</label>
                <textarea class="form-control" v-model="description"></textarea>
              </div>
              <div class="form-group">
                <button class="btn btn-primary" @click="changeStatus">
                  Хадгадах
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div
          class="col-md-12"
          v-html="data"
          style="border-left: 1px solid #ddd"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Customers.Trad",
  props: ["customerId"],
  data: function() {
    return {
      // app_numur_url: "https://app.numur.mn/",
      // app_numur_url: 'http://localhost:7001/',
      data: null,
      json: {},
      isLoading: false,
      form: {
        description: ""
      },
      status_list: [],
      status: null,
      description: "",
      a: 0,
      id: 0
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    customerId: {
      handler(customerId) {
        this.loadData();
      },
      deep: true
    }
  },
  methods: {
    loadData: function() {
      this.checkSession();
      this.isLoading = true;

      this.$http
        .get(this.$config.OYUTAN_API_URL + "ajax/get_init_data/", {
          params: { customer_id: this.customerId },
          emulateJSON: true
        })
        .then(
          (response) => {
            this.$data.status_list = response.data.status_list;

            this.$data.status = response.data.status;
            this.$data.id = response.data.id;
            this.loadView();
            this.isLoading = false;
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );
    },
    loadView: function() {
      this.$http
        .get(this.$config.OYUTAN_API_URL + "ajax/loan_request_view/", {
          params: { id: this.id },
          emulateJSON: true
        })
        .then(
          (response) => {
            // //console.logresponse);
            this.$data.data = response.data;
            this.isLoading = false;
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );
    },
    changeStatus: function() {
      this.isLoading = true;
      this.$http
        .get(this.$config.OYUTAN_API_URL + "ajax/change_status/", {
          params: {
            id: this.$data.id,
            status: this.$data.status,
            desc: this.$data.description
          },
          emulateJSON: true
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.showToast("Амжилттай", "Амжилттай хадгаллаа", "success");
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );
    }
  }
};
</script>
