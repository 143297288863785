<template>
    <div>
        <table class="table table-sm table-bordered mb-0" stacked="md" hover striped bordered small>

            <tr style="padding-top: 30px" class="bg-light">
                <th class="text-center">№</th>
                <th>Лавлагаа авсан ажилтан</th>
                <th>Лавлагаа авсан огноо</th>
                <th class="text-center">Зээлийн мэдээлэл харах</th>

            </tr>
            <tbody v-if="datas.length > 0">
                <tr v-for="(loan, index) in datas" :key="index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td>{{ loan.sysUserId.email }}</td>
                    <td>{{ loan.createdDate | formatDate }}</td>
                    <td class="text-center">
                        <b-button class="fa fa-download" @click="showModal(loan.fileUrl)"></b-button>
                  
                    </td>

                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="12">
                        <b-alert show class="mt-3 mr-3 ml-3" variant="info">Зээлийн түүх байхгүй байна</b-alert>
                    </td>
                </tr>

            </tbody>

        </table>

        <b-modal ref="my-modal" size="xl" title="Зээлийн мэдээлэл" hide-footer>
            <loading :active.sync="isLoading" 
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"></loading>

            <template>
                <ZMS :datas="data" :register="register" :cus_info="cus_info" :file_url="file_url"> </ZMS>
            </template>
            
        </b-modal>
    </div>
</template>
  
<script>
import ZMS from "./ZMS.vue";
import axios from "axios";
export default {
    name: "Cib.SainScoreFileList",
    props: {
        register: { type: String, default: "" },
        datas: { type: [Array, Object], default: () => [] },
    },
    data: () => {
        return {
            isLoading: false,
            data: [],
            file_url: '',
            cus_info: null,
            date: ''
        };
    },
    components: { axios, ZMS },
    methods: {
        showModal(fileUrl) {
            console.log('hello')
            this.file_url = fileUrl

            console.log('file_url: ' + this.datas[0])
            this.isLoading = true
            fetch(fileUrl)
            .then(res => res.json())
            .then(out =>
            {
                this.isLoading = false
              this.$data.data = out.inquiry
              this.$data.cus_info = out.cust
              this.$data.date = out.service.created_at
              console.log('date', out.service.created_at)
              console.log('Checkout this JSON! ', out)
              console.log('test')
              console.log(out.cust)
            }
              )
            .catch(err => { throw err });

            this.$refs["my-modal"].show();
        },
        hideModal() {
            this.$refs["my-modal"].hide();
        },
    }
};
</script>
  