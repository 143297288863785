<template>
  <b-card v-if="poll">
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
      :width="30"
      :height="30"
    ></loading>

    <h4>{{ poll.answer }}</h4>
    <div v-if="poll.customerQuestionId == 0">
      <div>
        <b-form-radio
          name="radio-size"
          v-model="selected_question"
          :value="item.id"
          :key="index"
          v-for="(item, index) in poll.questions"
        >
          {{ item.questions }}
        </b-form-radio>
      </div>
      <b-button
        class="mt-3"
        variant="success"
        size="sm"
        :disabled="!selected_question"
        @click="chooseQuestion()"
        >Хадгалах</b-button
      >
    </div>
    <div v-else>
      <h6 :key="index" v-for="(item, index) in poll.questions">
        <i
          :class="{
            'fa fa-check-square-o': poll.customerQuestionId == item.id,
            'fa fa-square-o': poll.customerQuestionId != item.id,
          }"
        ></i>
        {{ item.questions }}
      </h6>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "Customers.poll",
  props: ["customer"],
  data: function() {
    return {
      isLoading: true,
      poll: null,
      selected_question: null,
    };
  },
  watch: {
    customer: {
      handler(customer) {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    loadData: function() {
      this.checkSession();
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "PollWebService/get_active_poll", {
          params: { customerId: this.customer.id },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            // //console.log('asdad')
            // //console.log(response)
            this.$data.poll = response.body;
            if (this.$data.poll) {
              this.$data.selected_question = this.$data.poll.customerQuestionId;
            }
            this.isLoading = false;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    chooseQuestion: function() {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "PollWebService/set_poll_question", {
          params: {
            customerId: this.customer.id,
            pollId: this.$data.poll.id,
            questionId: this.$data.selected_question,
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            // //console.log(response);
            if (response.body.responseResultType == "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай хадгаллаа", "success");
              this.loadData();
            } else {
              this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
            }

            this.isLoading = false;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
