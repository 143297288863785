<template>
    <div class="pull-left">
        <b-button variant="primary" size="sm" @click="showModal">Регистр солих</b-button>

        <b-modal ref="my-modal" title="Регистрийн дугаар солих" hide-footer>
            <loading :active.sync="isLoading" 
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"></loading>
            <b-form @submit.stop.prevent="registerSave">
                <b-form-group label="Шинэ регистр:" label-for="input-change-phone">
                    <b-input-group>
                        <b-input-group-append>
                            <b-select class="rounded-0" v-model="rd.char1"  :options="chars.split('')"></b-select>
                        </b-input-group-append>
                        <b-input-group-append>
                            <b-select class="rounded-0" v-model="rd.char2"  :options="chars.split('')"></b-select>
                        </b-input-group-append>
                        <b-form-input maxlength="8" pattern="\d{8}" id="input-change-phone" v-model="rd.number" required placeholder="Регистр" @keypress="isNumber($event)"></b-form-input>
                    </b-input-group>
                </b-form-group>
                <div class="pull-right">
                    <b-button block type="submit" variant="primary">Хадгалах</b-button>
                </div>
            </b-form>
            
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'Customer.ChangeRegister',
    props: ['customerId', 'customer', 'callback'],
    data: function () {
        return {
            isLoading: false,
            form: {
                id: this.customerId,
                new_register: '',
                type: 'new_register'
            },
            rd: {
                char1: 'А',
                char2: 'А',
                number: ''
            },
            chars: 'АБВГДЕЁЖЗИЙКЛМНОӨПРСТУҮФХЦЧШЩЪЫЬЭЮЯ'
        }
    },
    watch: {
        rd: {
            handler (rd) {
                this.$data.form.new_register = rd.char1 + rd.char2 + rd.number
            },
            deep: true
        },
    },
    methods: {
        registerSave: function() {
            this.isLoading = true
            this.$data.form.token = this.$store.getters.token,
            this.$data.form.email = this.$store.getters.email,

            this.$http.post(
                this.$config.API_URL + 'CustomerWebService/change_data', 
                { data: JSON.stringify(this.$data.form)},
                { headers: this.$store.getters.httpHeader, emulateJSON: true }
                ).then(
                response => {
                    this.isLoading = false;
                    if(response.body.responseResultType == 'SUCCESS') {
                        this.showToast('Амжилттай', 'Амжилттай хадгалагдлаа', 'success')
                        this.hideModal()
                        this.callback()
                    } else {
                        let _ms = response.body.failureMessages.message;
                        for(var i in _ms) {
                            this.showToast('Анхаар', _ms[i].failureMessage, 'warning')
                        }
                    }
                }, 
                response => {
                    this.isLoading = false;
                    this.showToast('Алдаа', 'Алдаа гарсан байана', 'danger')
                }
                );
        },
        showModal() {
            this.$data.form.new_register = ''
            this.$refs['my-modal'].show()
        },
        hideModal() {
            this.$refs['my-modal'].hide()
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        }
    }
}
</script>
