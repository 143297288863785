<template>
  <b-card>
    <b-row>
      <b-col>
        <div>
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <div class="mb-3">
            <download-excel
              class="btn btn-primary pull-right ml-3"
              :fetch="fetchData"
              :fields="download.header"
              :before-generate="startDownload"
              :before-finish="finishDownload"
              :name="'Зээлийн түүх.xls'"
              v-if="items != undefined && items.length > 0"
            >
              <b-spinner
                small
                type="grow"
                v-show="download.loading"
              ></b-spinner>
              <span v-show="download.loading" class="ml-1">Уншиж байна</span>
              <i class="fa fa-download" v-show="!download.loading"></i>
              <span v-show="!download.loading" class="ml-1">Excel татах</span>
            </download-excel>

            <div class="pull-right">
              <b-form-select
                v-model="query.per_page"
                :options="[5, 10, 25, 100]"
                v-if="items != undefined && items.length > 0"
              >
              </b-form-select>
            </div>
            <b-button variant="primary" @click="showFilter()"
              >Шүүлт
              <i
                :class="{
                  'fa fa-caret-up': filterShow,
                  'fa fa-caret-down': !filterShow,
                }"
              ></i
            ></b-button>
          </div>
          <b-card v-show="filterShow" class="mb-3">
            <b-form @submit.stop.prevent="submitSearch">
              <b-row>
                <b-col md="2">
                  <b-form-group label="Дугаар">
                    <b-form-input
                      v-model="filter.search"
                      placeholder="Дугаар"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Үүсгэсэн огноо">
                    <b-input-group>
                      <date-picker
                        v-model="filter.startDate"
                        :config="{ format: 'YYYY-MM-DD', useCurrent: false }"
                        placeholder="Эхлэх огноо"
                      ></date-picker>
                      <date-picker
                        v-model="filter.endDate"
                        :config="{ format: 'YYYY-MM-DD', useCurrent: false }"
                        placeholder="Дуусах огноо"
                      ></date-picker>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Үүсгэсэн ажилтан" label-for="contract">
                    <b-select v-model="filter.sysUserId">
                      <option :value="null">Бүгд</option>
                      <option
                        :value="item.id"
                        :key="index"
                        v-for="(item, index) in sysUsers"
                        >@{{ item.firstname }}</option
                      >
                    </b-select>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    label="Баталгаажуулсан ажилтан"
                    label-for="contract"
                  >
                    <b-select v-model="filter.confirmedUserId">
                      <option :value="null">Бүгд</option>
                      <option
                        :value="item.id"
                        :key="index"
                        v-for="(item, index) in sysUsers"
                        >@{{ item.firstname }}</option
                      >
                    </b-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-button
                    variant="primary"
                    @click="submitSearch()"
                    style="margin-top: 28px;"
                    >Хайх</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </b-card>
          <div
            class="table-responsive"
            v-if="items != undefined && items.length > 0"
          >
            <b-table
              id="filter-table"
              stacked="md"
              @sort-changed="sortingChanged"
              hover
              bordered
              small
              responsive="sm"
              :items="items"
              :fields="columns"
              no-local-sorting
            >
              <template v-slot:cell(index)="data">
                {{ data.index + pagination.from }}
              </template>
              <template v-slot:cell(sysUserName)="data">
                <strong class="text-blue"
                  >{{ data.item.sysUserName != null ? "@" : ""
                  }}{{ data.item.sysUserName }}</strong
                >
              </template>
              <template v-slot:cell(confirmedUserName)="data">
                <strong class="text-blue"
                  >{{ data.item.confirmedUserName != null ? "@" : ""
                  }}{{ data.item.confirmedUserName }}</strong
                >
              </template>
              <template v-slot:cell(isConfirmed)="data">
                <b-badge v-if="data.item.isConfirmed" variant="primary"
                  >Баталгаажсан</b-badge
                >
                <b-button
                  v-else
                  variant="primary"
                  size="sm"
                  @click="confirmed(data.item)"
                  >Баталгаажуулах</b-button
                >
              </template>
              <template v-slot:cell(seeHTML)="data">
                <b-button
                  variant="primary"
                  size="sm"
                  @click="seeHTML(data.item)"
                  >Харах</b-button
                >
              </template>
              isConfirmed
            </b-table>

            <div slot="footer" class="mb-0 mt-3">
              <nav class="pull-right" v-if="pagination.total > query.per_page">
                <b-pagination
                  class="mb-0"
                  :total-rows="pagination.total"
                  :per-page="query.per_page"
                  v-model="query.page"
                />
              </nav>
              <p class="mb-0">
                {{ pagination.total }} өгөгдлөөс
                {{ pagination.to ? pagination.from : 0 }} -
                {{ pagination.to }} өгөгдөл харагдаж байна.
              </p>
            </div>
          </div>

          <b-alert variant="warning" v-else show class="mb-0">
            Мэдээлэл байхгүй байна
          </b-alert>

          <b-modal ref="confirm-modal" size="sm" @ok="confimedStatement">
            <template slot="modal-title">
              Баталгаажуулалт
            </template>
            <div class="d-block">
              <p class="text-danger mb-0">
                Та баталгаажуулахдаа итгэлтэй байна уу?
              </p>
            </div>
            <template slot="modal-footer"></template>
          </b-modal>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from "axios";

import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

export default {
  name: "sales-new-list",
  components: { axios, datePicker },
  props: ["customerId"],
  data: function() {
    return {
      isLoading: false,
      filterShow: false,
      selectedSales: null,
      query: {
        id: 0,
        page: 1,
        per_page: 10,
        orderDirection: "ASC",
        orderBy: "number",
        filter: "{}",
      },
      filter: {
        customerId: this.customerId,
        search: "",
        startDate: "",
        endDate: "",
        sysUserId: null,
        confirmedUserId: null,
        typeId: 1,
      },
      pagination: {
        total: 0,
        from: 0,
        to: 0,
      },
      orderDesc: false,
      items: [],
      types: [],
      sysUsers: [],
      search: "",
      download: {
        loading: false,
        header: {},
      },
      columns: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Дугаар",
          key: "fullNumber",
          sortable: true,
          sort_key: "number",
        },
        {
          label: "Үүсгэсэн огноо",
          key: "createdDate",
          sortable: true,
          sort_key: "createdDate",
        },
        {
          label: "Үүсгэсэн ажилтан",
          key: "sysUserName",
          sortable: true,
          sort_key: "sysUser",
        },
        {
          label: "Баталгаажуулах",
          key: "isConfirmed",
          sortable: true,
          sort_key: "isConfirmed",
        },
        {
          label: "Баталгаажуулсан огноо",
          key: "confirmedDate",
          sortable: true,
          sort_key: "confirmedDate",
        },
        {
          label: "Баталгаажуулсан ажилтан",
          key: "confirmedUserName",
          sortable: true,
          sort_key: "confirmedUser",
        },
        { label: "Харах", key: "seeHTML" },
      ],
      isActiveLoan: false,
      selectedStatement: null,
    };
  },
  watch: {
    query: {
      handler(query) {
        this.loadData();
      },
      deep: true,
    },
  },
  created: function() {
    this.initData();
    this.$data.download.header = {
      "№": "index",
      Дугаар: "fullNumber",
      "Үүсгэсэн огноо": "registeredDate",
      "Үүсгэсэн ажилтан": "sysUserName",
      Баталгаажуулах: "isConfirmed",
      "Баталгаажуулсан огноо": "operatorName",
      "Баталгаажуулсан ажилтан": "confirmedUserName",
    };
    this.$data.query.filter = JSON.stringify(this.$data.filter);
    this.$data.page = 1;
    this.loadData();
  },
  methods: {
    initData: function() {
      // this.$http.get(this.$config.API_URL + 'StatementWebService/get_statement_type_list',
      //     {
      //         headers: this.$store.getters.httpHeader,
      //         emulateJSON: true
      //     })
      // .then(response => {
      //     //console.log(response)
      //     this.$data.types = response.body.data
      // }, response => {

      // });

      this.$http
        .get(this.$config.API_URL + "StatementWebService/get_sys_user_list", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            // //console.log(response);
            this.$data.sysUsers = response.body.data;
          },
          (response) => {}
        );
    },
    loadData: function() {
      this.checkSession();
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "StatementWebService/get_statement_list", {
          params: this.$data.query,
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            // //console.log(response);
            this.isLoading = false;
            this.$data.items = response.body.data;
            this.$data.pagination.total = response.body.total;
            this.$data.pagination.from = response.body.from;
            this.$data.pagination.to = response.body.to;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    sortingChanged(ctx) {
      let _field = "";
      for (let i in this.fields) {
        if (this.fields[i].key == ctx.sortBy) {
          this.$data.query.orderBy = this.fields[i].sort_key;
          break;
        }
      }
      this.$data.query.orderDirection = ctx.sortDesc ? "ASC" : "DESC";
      this.loadData();
    },
    submitSearch: function() {
      // this.$router.replace({ name: this.$route.name, query: this.$data.query})
      this.$data.query.filter = JSON.stringify(this.$data.filter);
      this.$data.page = 1;
      this.loadData();
    },
    async fetchData() {
      if (this.download.loading) {
        this.download.loading = true;
        const response = await axios.get(
          this.$config.API_URL + "StatementWebService/get_statement_list",
          {
            params: {
              page: 1,
              per_page: 0,
              orderDirection: this.$data.query.orderDirection,
              orderBy: this.$data.query.orderBy,
              filter: this.$data.query.filter,
              id: this.$data.query.id,
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        );
        if (response.data.status == 500) {
          this.showToast(
            "Алдаа",
            "Алдаа гарсан байна. Дахин оролдоно уу",
            "danger"
          );
          this.download.loading = false;
          return [];
        }

        let _result = [];
        for (let i in response.data.data) {
          let _item = response.data.data[i];
          _item.index = parseInt(i) + 1;
          _result.push(_item);
        }
        return _result;
      }
      return [];
    },
    startDownload() {
      this.download.loading = true;
    },
    finishDownload() {
      this.download.loading = false;
    },
    showFilter() {
      this.$data.filterShow = !this.$data.filterShow;
    },
    confirmed: function(_item) {
      if (this.checkPermission("admin.statement.judge_confirmed")) {
        this.$data.selectedStatement = _item;
        this.$refs["confirm-modal"].show();
      } else {
        this.showToast("Анхаар", "Хандах эрх байхгүй байна", "warning");
      }
    },
    seeHTML: function(_item) {
      var mywindow = window.open(
        "https://admin.numur.mn/statement/" + _item.id + ".html",
        "Print",
        "_blank"
      );
      mywindow.open("");
    },
    confimedStatement: function() {
      this.$http
        .post(
          this.$config.API_URL + "StatementWebService/confirm",
          { id: this.$data.selectedStatement.id },
          { headers: this.$store.getters.httpHeader, emulateJSON: true }
        )
        .then(
          (response) => {
            this.$refs["confirm-modal"].hide();
            this.isLoading = false;
            if (response.body.responseResultType == "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
              this.$refs["confirm-modal"].hide();
              this.loadData();
            } else {
              let _ms = response.body.failureMessages.message;
              for (var i in _ms) {
                this.showToast("Анхаар", _ms[i].failureMessage, "warning");
              }
            }
          },
          (response) => {
            this.$refs["confirm-modal"].hide();
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
  },
};
</script>
