<template>
  <b-card>
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
    ></loading>
    <h6>
      Баталгаажуулах код
      <b-button
        size="sm"
        variant="secondary"
        class="pull-right"
        @click="getConfirmCode(true)"
        >шинэ код авах</b-button
      >
      
    </h6>
    <div>
      <p class="mt-3 mb-0 ">
        <span :class="resultType">{{ result }}</span>
      </p>
    </div>
  </b-card>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "Customer.PayRequest",
  props: ["customerId", "customer", "callback"],
  components: {
    Treeselect,
  },
  data: function() {
    return {
      isLoading: false,
      selected_type: false,
      result: "",
      resultType: "text-primary",
    };
  },
  created() {
    this.getConfirmCode(false);
  },
  methods: {
    getConfirmCode: function(is_new) {
      this.isLoading = true;
      this.$data.resultType = "text-primary";
      this.$http
        .post(
          this.$config.API_URL + "CustomerWebService/get_confirm_code",
          {
            data: JSON.stringify({
              token: this.$store.getters.token,
              email: this.$store.getters.email,
              is_new: is_new,
              customer_id: this.customerId,
            }),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            // //console.log("asdfasdfasdfhaskldfhaskdhfakl jdaskljdfh asklhf");
            // //console.log(response);
            if (response.body.responseResultType == "SUCCESS") {
              this.$data.resultType = "text-primary";
              this.$data.result = response.body.successData;
            } else {
              this.$data.resultType = "text-danger";
              this.$data.result = "Алдаа гарсан байна";
            }
            this.isLoading = false;
          },
          (response) => {
            this.isLoading = false;
            this.$data.resultType = "text-danger";
            this.$data.result = "Алдаа гарсан байна";
          }
        );
    },
  },
};
</script>
