<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
      :width="30"
      :height="30"
    ></loading>

    <b-alert variant="warning" v-if="data == null" show class="mb-0"
      >Мэдээлэл байхгүй байна</b-alert
    >

    <div v-else>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Төлөв</label>
                <select class="form-control" v-model="status">
                  <option
                    :value="value"
                    v-for="(name, value, index) in status_list"
                    :key="index"
                    >{{ name }}</option
                  >
                </select>
              </div>
              <div class="form-group" v-if="status == 'UN_CONFIRM'">
                <label for="">Орлого</label>
                <select class="form-control" v-model="un_confirm_income">
                  <option
                    :value="item.id"
                    v-for="(item, index) in un_confirm_income_list"
                    :key="index"
                    >{{ item.name }}</option
                  >
                </select>
              </div>
              <div class="form-group" v-if="status == 'UN_CONFIRM'">
                <label for="">Зээлийн түүх</label>
                <select class="form-control" v-model="un_confirm_loan_history">
                  <option
                    :value="item.id"
                    v-for="(item, index) in un_confirm_loan_history_list"
                    :key="index"
                    >{{ item.name }}</option
                  >
                </select>
              </div>
              <div class="form-group" v-if="status == 'UN_CONFIRM'">
                <label for="">Бусад</label>
                {{ un_confirm_other }}
                <select class="form-control" v-model="un_confirm_other">
                  <option
                    :value="item.id"
                    v-for="(item, index) in un_confirm_other_list"
                    :key="index"
                    >{{ item.name }}</option
                  >
                </select>
              </div>
              <div class="form-group">
                <label for="">Тайлбар</label>
                <textarea class="form-control" v-model="description"></textarea>
              </div>
              <div class="form-group">
                <button class="btn btn-primary" @click="changeStatus">
                  Хадгадах
                </button>
              </div>
            </div>
          </div>
          <table class="table table-sm" v-if="result_data != {}">
            <tr style="padding-top: 30px">
              <th>Өрхийн орлого</th>
              <th>Зээлийн өмнө</th>
              <th>Зээлийн дараа</th>
              <th>Тайлбар</th>
            </tr>
            <tr v-for="(result_item, index) in result_map_income" :key="index">
              <td>{{ result_item.name }}</td>
              <td>
                <money
                  class="form-control text-right"
                  type="text"
                  v-model="result_data[result_item.code]['before_amount']"
                  placeholder=""
                  suffix=" ₮"
                  :precision="0"
                ></money>
              </td>
              <td>
                <money
                  class="form-control text-right"
                  type="text"
                  v-model="result_data[result_item.code]['after_amount']"
                  placeholder=""
                  suffix=" ₮"
                  :precision="0"
                ></money>
              </td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  v-model="result_data[result_item.code]['description']"
                />
              </td>
            </tr>
            <tr style="padding-top: 30px">
              <th>Өрхийн зарлага</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr
              v-for="(result_item, index) in result_map_outcome"
              :key="index + 100"
            >
              <td>{{ result_item.name }}</td>
              <td>
                <money
                  class="form-control text-right"
                  type="text"
                  v-model="result_data[result_item.code]['before_amount']"
                  placeholder=""
                  suffix=" ₮"
                  :precision="0"
                ></money>
              </td>
              <td>
                <money
                  class="form-control text-right"
                  type="text"
                  v-model="result_data[result_item.code]['after_amount']"
                  placeholder=""
                  suffix=" ₮"
                  :precision="0"
                ></money>
              </td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  v-model="result_data[result_item.code]['description']"
                />
              </td>
            </tr>
          </table>

          <button type="submir" class="btn btn-primary" @click="submitData">
            Хадгалах
          </button>
        </div>
        <div
          class="col-md-6"
          v-html="data"
          style="border-left: 1px solid #ddd"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Customers.Trad",
  props: ["customerId"],
  data: function() {
    return {
      app_numur_url: "https://app.numur.mn/",
      // app_numur_url: 'http://localhost:7001/',
      data: null,
      json: {},
      isLoading: false,
      form: {
        description: "",
      },
      result_map_income: [],
      result_map_outcome: [],
      status_list: [],
      status: null,

      un_confirm_income_list: [],
      un_confirm_income: null,

      un_confirm_loan_history_list: [],
      un_confirm_loan_history: null,

      un_confirm_other_list: [],
      un_confirm_other: null,

      description: "",
      a: 0,
      result_data: {},
    };
  },
  created() {
    this.loadData();
    this.loadDataJson();
  },
  watch: {
    customerId: {
      handler(customerId) {
        this.loadData();
        this.loadDataJson();
      },
      deep: true,
    },
  },
  methods: {
    loadData: function() {
      this.checkSession();
      this.isLoading = true;
      this.$http
        .get(this.$config.API_NUMUR_URL + "loan_request_view/", {
          params: { customer_id: this.customerId },
          emulateJSON: true,
        })
        .then(
          (response) => {
            // //console.logresponse);
            this.$data.data = response.data;
            this.isLoading = false;
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );

      this.$data.result_map_income = [];
      this.$data.result_map_outcome = [];
      this.$http
        .get(this.$config.API_NUMUR_URL + "api/get_init_data/", {
          params: { customer_id: this.customerId },
          emulateJSON: true,
        })
        .then(
          (response) => {
            // //console.logresponse);
            for (var i in response.data.result_map) {
              var item = response.data.result_map[i];
              if (item.type == "income") {
                this.$data.result_map_income.push(item);
              } else {
                this.$data.result_map_outcome.push(item);
              }
            }

            this.$data.status_list = response.data.status_list;
            this.$data.un_confirm_income_list = response.data.un_confirm_income;
            this.$data.un_confirm_loan_history_list =
              response.data.un_confirm_loan_history;
            this.$data.un_confirm_other_list = response.data.un_confirm_other;

            this.$data.status = response.data.status;

            this.isLoading = false;
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );

      this.$http
        .get(this.$config.API_NUMUR_URL + "api/get_result_data/", {
          params: { customer_id: this.customerId },
          emulateJSON: true,
        })
        .then(
          (response) => {
            // //console.logresponse);
            this.$data.result_data = response.data.data;
            this.isLoading = false;
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );
    },
    loadDataJson: function() {
      this.checkSession();
      this.isLoading = true;
      this.$http
        .get(this.$config.API_NUMUR_URL + "api/loan_request/", {
          params: { customer_id: this.customerId },
          emulateJSON: true,
        })
        .then(
          (response) => {
            if (response.data.success) {
              this.$data.json = response.data.data;
              this.$data.form.description = this.$data.json.result;
            }

            this.isLoading = false;
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );
    },
    submitData: function() {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_NUMUR_URL + "api/loan_request/save_result/", {
          params: {
            customer_id: this.customerId,
            data: JSON.stringify(this.$data.result_data),
          },
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.showToast("Амжилттай", "Амжилттай хадгаллаа", "success");
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );
    },
    changeStatus: function() {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_NUMUR_URL + "api/loan_request/change_status/", {
          params: {
            customer_id: this.customerId,
            status: this.$data.status,
            unconfirm_income: this.$data.un_confirm_income,
            unconfirm_loan_history: this.$data.un_confirm_loan_history,
            unconfirm_other: this.$data.un_confirm_other,
            desc: this.$data.description,
          },
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.showToast("Амжилттай", "Амжилттай хадгаллаа", "success");
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
