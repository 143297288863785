<template>
    <b-card header="">
        <template #header>
            <h5 class="mb-0">Өр зээлгүй тодорхойлолт</h5>
        </template>
        <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
        </loading>

        <b-form-group label="Дугаар">
            <b-form-input placeholder="Дугаар" v-model="form.number"></b-form-input>
        </b-form-group>
        <b-form-group label="Хаана">
            <b-form-input placeholder="Бичнэ үү" v-model="form.where"></b-form-input>
        </b-form-group>
        <b-button variant="success" class="pull-left mr-3" :disabled="emailButtonLoading" @click="sendNoLoanHTML()" v-if="checkPermission(
                'admin.loan-repayment-overdue.create-statement-no-loan'
            )
            "><span v-if="!emailButtonLoading"> <i class="fa fa-envelope-o"></i> Имэйл илгээх</span>
            <span v-else>Уншиж байна</span></b-button>

        <b-button variant="info" class="pull-left mr-3" @click="getNoLoanHTML()" v-if="checkPermission(
                'admin.loan-repayment-overdue.create-statement-no-loan'
            )
            "> <i class="fa fa-file-o"></i> харах</b-button>
    </b-card>
</template>

<script>

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    name: 'Customer.Message',
    props: ['customerId', 'customer', 'callback'],
    components: {
        Treeselect,
    },
    data: function () {
        return {
            emailButtonLoading: false,
            isLoading: false,
            form: {
                number: null,
                where: null
            },
        }
    },
    methods: {
        validated: function () {
            let r = true
            if (this.$data.form.number == null || this.$data.form.number == '') {
                r = false
            }
            if (this.$data.form.where == null || this.$data.form.where == '') {
                r = false
            }
            if (!r) {
                this.showToast("Анхаар", "Заавал бөглөх талбар бөглөөгүй байна", "warning");
            }
            return r;
        },
        sendNoLoanHTML: function () {
            if (this.validated()) {
                this.emailButtonLoading = true;
                this.$http
                    .get(this.$config.API_URL + "StatementWebService/send_no_loan_html", {
                        params: { 
                            id: this.customerId, 
                            number: this.$data.form.number, 
                            where: this.$data.form.where
                        },
                        emulateJSON: true,
                        headers: this.$store.getters.httpHeader,
                    })
                    .then(
                        (response) => {
                            if (response.body.responseResultType === "SUCCESS") {
                                this.showToast("Амжилттай", "Амжилттай илгээлээ", "success");
                                this.loadData();
                            } else {
                                let _ms = response.body.failureMessages.message;
                                for (var i in _ms) {
                                    this.showToast("Анхаар", _ms[i].failureMessage, "warning");
                                }
                            }
                            this.emailButtonLoading = false;
                        },
                        () => {
                            this.emailButtonLoading = false;
                        }
                    );
            }
        },
        getNoLoanHTML: function (item) {
            if (this.validated()) {
                this.isLoading = true;
                this.$http
                    .get(this.$config.API_URL + "StatementWebService/no_loan_html", {
                        params: { 
                            id: this.customerId,
                            number: this.$data.form.number, 
                            where: this.$data.form.where
                        },
                        emulateJSON: true,
                        headers: this.$store.getters.httpHeader,
                    })
                    .then(
                        (response) => {
                            this.isLoading = false;
                            var mywindow = window.open("", "Print", "_blank");
                            if (response.body.successData) {
                                mywindow.document.write(response.body.successData);

                                // mywindow.print()
                                // mywindow.close()
                            } else {
                                mywindow.document.write("Амжилтгүй");
                            }

                            // mywindow.document.close();
                            // mywindow.open("");
                            // mywindow.close('')

                            // //console.log(response)
                        },
                        (response) => {
                            this.isLoading = false;
                        }
                    );
            }

        },
    }
}
</script>
