<template>
  <download-excel
    class="btn btn-info btn-sm pull-right"
    :fields="header"
    :fetch="fetchData"
    :before-generate="startDownload"
    :before-finish="finishDownload"
    :name="'Хүү хуримтлуулсан түүх (' + account.accountNumber + ').xls'"
  >
    <b-spinner small type="grow" v-show="isLoading"></b-spinner>
    <span v-show="isLoading" class="ml-1">Уншиж байна</span>
    <i class="fa fa-download" v-show="!isLoading"></i>
    <span v-show="!isLoading" class="ml-1">Хураитлагдсан хүү</span>
  </download-excel>
</template>

<script>
import axios from "axios";
export default {
  name: "Customer.LoanAccountInterest",
  components: { axios },
  props: ["account"],
  data: function() {
    return {
      isLoading: false,
      header: {
        "#": 'index',
        Огноо: "action_date",
        "Зээлийн үлдэгдэл": "loan_balance",
        Хүү: "interest_amount",
        "Өдрийн хүү": "day_interest"
      }
    };
  },
  methods: {
    async fetchData() {
      if (this.isLoading) {
        this.isLoading = true;
        const response = await axios.get(
          this.$config.API_URL + "LoanWebService/get_interest_calculation",
          {
            params: {
              id: this.account.id
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true
          }
        );
        console.log(response);
        if (response.data.status == 500) {
          this.showToast(
            "Алдаа",
            "Алдаа гарсан байна. Дахин оролдоно уу",
            "danger"
          );
          this.isLoading = false;
          return [];
        }

        let _result = [];
        for (let i in response.data.data) {
          let _item = response.data.data[i];
          _item.index = parseInt(i) + 1;
          _result.push(_item);
        }
        return _result;
      }
      return [];
    },
    startDownload() {
      this.isLoading = true;
    },
    finishDownload() {
      this.isLoading = false;
    }
  }
};
</script>
