<template>

    <div>
        <b-card no-focus>
        <b-form-group 
            id="input-loan-tag"
            label="Хэрэглэгчийн таг"
            label-for="input-tag">
        
              <multiselect 
              v-model="form.customer_tags" 
              tag-placeholder="Add this as new tag" 
              placeholder="Таг хайх" 
              label="name" 
              track-by="code" 
              :options="customer_tags" 
              :multiple="true" 
              :precision="0"
              :taggable="true" @tag="addTag">
            </multiselect>
          </b-form-group>

          <b-form-group class="mb-0">
                <b-button variant="success" @click="saveLoanTag">Хадгалах</b-button>
            </b-form-group>
        </b-card>
    </div>
</template>
<script>
export default {
    name: "Loan.Tag",
    props: ["customerId"],
    data: function() {
      return {
        noteReload: false,
        isLoading: false,
        form: {
            customer_tags: []
        },
        customer_tags: [],
      };
    },
    created() {
      this.initData();
    },
    methods: {
        addTag(newTag) {
            console.log(newTag)
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
            }
            this.customer_tags.push(tag)
            this.$data.form.customer_tags.push(tag)
        },
        initData: function() {
            console.log('hello')
            this.$http
            .get(this.$config.API_URL + "CustomerWebService/get_customer_tag_list", {
                params: {
                    page: 1,
                    per_page: 0,
                    orderBy: "code",
                },
                headers: this.$store.getters.httpHeader,
                emulateJSON: true,
            })
            .then(
            (response) => {
                this.isLoading = false;
                console.log('hello')
                console.log(response.body.list)
                for (var i in response.body.list) {
                    if (response.body.list[i].isActive) {
                        this.$data.customer_tags.push({
                        name:response.body.list[i].name,
                        code: response.body.list[i].code,
                        id: response.body.list[i].id
                        });
                    }
                }
            });

            this.$http
            .get(this.$config.API_URL + "CustomerWebService/get_selected_customer_tags", {
                params: {
                    orderBy: "code",
                    id: this.customerId
                },
                headers: this.$store.getters.httpHeader,
                emulateJSON: true,
            })
            .then(
            (response) => {
                this.isLoading = false;
                console.log('hello')
                console.log(response.body.list)
                for (var i in response.body.list) {
                    if (response.body.list[i].isActive) {
                        this.$data.form.customer_tags.push({
                        name:response.body.list[i].name,
                        code: response.body.list[i].code,
                        id: response.body.list[i].id
                        });
                    }
                }
            });

        },
        saveLoanTag: function() {
            console.log('hello')
            let customer_tag_ids = []
            for (var i in this.$data.form.customer_tags) {
                customer_tag_ids.push(this.$data.form.customer_tags[i].id)
            }
            console.log(customer_tag_ids)

            this.isLoading = true;
            this.$http
                .post(
                    this.$config.API_URL + "CustomerWebService/save_customer_tag",
                    {
                        id: this.customerId,
                        customer_tag_ids: customer_tag_ids.join(","),
                    },
                    { headers: this.$store.getters.httpHeader, emulateJSON: true }
                )
                .then(
                    (response) => {
                    this.isLoading = false;
                    console.log(response)
                    if (response.body.responseResultType == "SUCCESS") {
                        this.showToast(
                        "Амжилттай",
                        "Амжилттай хадгалагдлаа",
                        "success"
                        );
                    } else {
                        let _ms = response.body.failureMessages.message;
                        for (var i in _ms) {
                        this.showToast("Анхаар", _ms[i].failureMessage, "warning");
                        }
                    }
                    },
                    (response) => {
                    this.isLoading = false;
                    this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
                    }
                );
            }
    }
  };

 
  </script>
  